@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  @apply bg-primary text-primary
}

.rounded-div {
  @apply border border-secondary rounded-2xl shadow-xl bg-primary px-2 max-w-[1140px] w-full mx-auto
}

.dark {
  --color-bg-primary: #2d3748;
  --color-bg-primary: #2d3748;
  --color-bg-secondary: #283141;
  --color-text-primary: #dbdbdb;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #81e6d9;
  --color-bg-input: #4a5361;
  --color-bg-button: #81e6d9;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #edf2f7;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
  --color-bg-input: #edf2f7;
  --color-bg-button: #2b6cb0;
}